function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { store } from "@common";
import config from "@common/services/config";
import { checkLockStatusForChannel, checkVisibleStatusForChannel, diffObjects, findDeviceSettingsItem } from "@common/utils/helpers";
import { CURRENCIES, DIRECTION_DOWN, DIRECTION_LEFT, DIRECTION_NONE, DIRECTION_RIGHT, DIRECTION_UP, HTML_TEMPLATE_CHARGEBEE_SITE, MOBILE_TYPE_PX, ONE_HOUR_IN_SECONDS, REGEX_EMAIL, REGEX_PASSWORD, SMALL_MOBILE_TYPE_PX, TABLET_TYPE_PX, TIMEZONE, TIME_FORMAT } from "@config/constants";
import { isEmpty, isNil } from "lodash";
// FACEPALM
// TEMP vars
export var customerID;
export var setCustomerID = function(id) {
    if (id) {
        customerID = id;
    }
};
//
export var replaceWith = function(string, replacements) {
    var stringToReplace = string;
    if (!stringToReplace) {
        return "";
    }
    var matches = stringToReplace.match(/({\d+})+/g);
    if (Array.isArray(matches)) {
        if (Array.isArray(replacements)) {
            replacements.map(function(replacement, index) {
                if (typeof replacement === "string" || typeof replacement === "number") {
                    stringToReplace = stringToReplace.replace(matches[index], replacement);
                }
            });
        } else if (typeof replacements === "string" || typeof replacements === "number") {
            stringToReplace = stringToReplace.replace(matches[0], replacements);
        }
    }
    if (customerID) {
        stringToReplace = stringToReplace.replace("telekabelonline.bg/fast-start", "telekabelonline.bg/fast-start".concat("?partyId=".concat(customerID)));
    }
    return stringToReplace;
};
/**
 * Format sconds in HH:MM:SS format
 * @param sec {number}
 * @returns {string}
 */ export var toHHMMSS = function(sec) {
    var secondsToConvert = Number.parseInt(sec);
    var secondsToHours = Number.parseInt(secondsToConvert / ONE_HOUR_IN_SECONDS);
    var secondsToMinutes = Number.parseInt(secondsToConvert / 60) % 60;
    var secondsLeft = Number.parseInt(secondsToConvert) % 60;
    var hours = secondsToHours <= 9 ? "0".concat(secondsToHours) : secondsToHours;
    var minutes = secondsToMinutes <= 9 ? "0".concat(secondsToMinutes) : secondsToMinutes;
    var seconds = secondsLeft <= 9 ? "0".concat(secondsLeft) : secondsLeft;
    return hours !== "00" ? "".concat(hours, ":").concat(minutes, ":").concat(seconds) : "".concat(minutes, ":").concat(seconds);
};
export var msToDDHHMMSS = function(ms) {
    var days = Math.floor(ms / (24 * 60 * 60 * 1000));
    var daysMS = ms % (24 * 60 * 60 * 1000);
    var hours = Math.floor(daysMS / (60 * 60 * 1000));
    var hoursMS = ms % (60 * 60 * 1000);
    var minutes = Math.floor(hoursMS / (60 * 1000));
    var minutesMS = ms % (60 * 1000);
    var sec = Math.floor(minutesMS / 1000);
    return {
        days: pad(days),
        hours: pad(hours),
        minutes: pad(minutes),
        sec: pad(sec)
    };
};
var pad = function(number) {
    return number < 10 ? "0" + number : number;
};
/**
 * If data is object - transform in array and sort from min to max, if data is array - sort from min to max
 * @param data {Array | Object}
 * @param isAlphabetical {Boolean}
 * @returns {Array}
 */ export var sort = function(data, isAlphabetical) {
    var sortBySettings = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true, deviceSettingsItemList = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : undefined;
    var itemList = undefined;
    try {
        itemList = deviceSettingsItemList || store.getState().profileSettings.web_settings.DeviceSettingsItemList;
    } catch (error) {}
    var array = Array.isArray(data) ? data : Object.values(data);
    try {
        return array.sort(function(a, b) {
            var itemA = findDeviceSettingsItem(itemList, a.id);
            var itemB = findDeviceSettingsItem(itemList, b.id);
            // access is either 0 or 1
            if (a.access !== b.access) {
                return a.access < b.access ? 1 : -1;
            }
            if (isAlphabetical) {
                return a.name > b.name ? 1 : -1;
            } else if (itemList && itemA && itemB && sortBySettings) {
                return itemA.channelSettings.position - itemB.channelSettings.position;
            }
            // Fallback to position from the supplier
            return a.position - b.position;
        });
    } catch (e) {
        console.error(e);
        return [];
    }
};
/**
 * Check if the user is on mobile device
 * @type {{Android: isMobile.Android, BlackBerry: isMobile.BlackBerry, iOS: isMobile.iOS, Opera: isMobile.Opera, Windows: isMobile.Windows, any: isMobile.any}}
 */ export var isMobile = {
    Android: function() {
        return !!navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return !!navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return !!navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.includes("Mac") && "ontouchend" in document;
    },
    Opera: function() {
        return !!navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return !!navigator.userAgent.match(/IEMobile/i);
    },
    isTV: function() {
        return !!navigator.userAgent.match(/TV/i);
    },
    WebOS: function() {
        return !!navigator.userAgent.match(/Web0S|NetCast/i);
    },
    Tizen: function() {
        return !!navigator.userAgent.match(/Tizen/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows()) && window.innerWidth <= TABLET_TYPE_PX;
    }
};
/**
 * Detects if browser is Safari
 * @returns {boolean}
 */ export var isSafariBrowser = function() {
    return !!(navigator.vendor && navigator.vendor.indexOf("Apple") > -1 && navigator.userAgent && navigator.userAgent.indexOf("CriOS") === -1 && navigator.userAgent.indexOf("FxiOS") === -1);
};
/**
 * Calculate show current progress in percentages. All units are in seconds;
 * @param start {number}
 * @param end {number}
 * @param currentTime {number}
 * @returns {number}
 */ export var calculateTimePercentages = function(start, end, currentTime) {
    var percentages = 0;
    var now = typeof currentTime !== "undefined" ? currentTime : new Date().getTime() / 1000 - config.timestampOffset;
    if (now >= end) {
        percentages = 100;
    } else if (now <= start) {
        percentages = 0;
    } else {
        var duration = end - start;
        var progress = now - start;
        var percentageInSeconds = duration / 100;
        percentages = progress / percentageInSeconds;
    }
    return percentages;
};
/**
 * Calculate how many px is given percentages of width in px
 */ export var calculatePercentageInPixels = function(percentages, width) {
    return width * percentages / 100;
};
/**
 * Returns functions to execute when touchstart event and touchend event occur if swipe event is detected
 * @param callback - function to execute on touchend event if condition is met
 */ export var addSwipeEvent = function(callback) {
    var swipeDir, startX, startY, distX, distY, dist = null, elapsedTime, startTime;
    var threshold = 150, restraint = 100, allowedTime = 300, handleSwipe = callback;
    var touchStart = function(e) {
        var touchObject = e.changedTouches[0];
        swipeDir = DIRECTION_NONE;
        dist = 0;
        startX = touchObject.pageX;
        startY = touchObject.pageY;
        startTime = new Date().getTime(); // record time when finger first makes contact with surface
    };
    var touchEnd = function(e) {
        var touchObject = e.changedTouches[0];
        distX = touchObject.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
        distY = touchObject.pageY - startY; // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime; // get time elapsed
        if (elapsedTime <= allowedTime) {
            // first condition for swipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
                // 2nd condition for horizontal swipe met
                swipeDir = distX < 0 ? DIRECTION_LEFT : DIRECTION_RIGHT; // if dist traveled is negative, it indicates left swipe
            } else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) {
                // 2nd condition for vertical swipe met
                swipeDir = distY < 0 ? DIRECTION_UP : DIRECTION_DOWN; // if dist traveled is negative, it indicates up swipe
            }
        }
        handleSwipe(e, swipeDir);
    };
    return {
        touchEnd: touchEnd,
        touchStart: touchStart
    };
};
/**
 * Takes an array, copies it and then splits it
 * to a new array of arrays with lengths
 * equal (or smaller) than the limit;
 * @param list {Array} - the array to be partitioned
 * @param limit {number} - the partition size
 * @returns {Array}
 */ export var partitionArray = function(originalList, limit) {
    var list = originalList.map(function(item) {
        var id = item.id, rest = _object_without_properties(item, [
            "id"
        ]);
        return _object_spread_props(_object_spread({}, rest), {
            id: Number.parseInt(id)
        });
    });
    var copy = list.slice();
    var result = [];
    while(copy.length){
        result.push(copy.splice(0, limit));
    }
    return result;
};
/**
 * Takes the 'search' key of the 'location' object
 * provided by the React router and parses it to an object.
 * The keys correspond to the param's name and the
 * value depends on the specific case:
 *  - name=ivan turns into {name: ivan}
 *  - name= turns into {name: ""}
 *  - name turns into {name: true}
 * @param queryParamsString {string}
 * @returns {Object}
 */ export var parseQuery = function(queryParamsString) {
    var normalized = queryParamsString.trim().replace("?", "");
    var arrayOfParams = normalized.split("&");
    return arrayOfParams.reduce(function(current, param) {
        var newResult = current;
        var hasEqualSign = param.indexOf("=") >= 0;
        var keyValue = param.trim().split("=");
        if (param !== "") {
            if (keyValue[0] && hasEqualSign) {
                newResult = _object_spread_props(_object_spread({}, current), _define_property({}, keyValue[0], keyValue[1]));
            } else if (keyValue[0] && !hasEqualSign) {
                newResult = _object_spread_props(_object_spread({}, current), _define_property({}, keyValue[0], true));
            }
        }
        return newResult;
    }, {});
};
/**
 * Formats time from timestamp to HH:MM format in a timezone
 * @param time {number}
 * @returns {string}
 */ export var formatTime = function(time) {
    var timeInMilliseconds = secondsToMilliseconds(time);
    return new Date(timeInMilliseconds).toLocaleTimeString(TIME_FORMAT, {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: TIMEZONE
    });
};
/**
 * Get a string formatted like: 30 November, 16:00 - 19:00
 * @param start {number} - show start timestamp in seconds
 * @param stop {number} - show end timestamp in seconds
 * @param months {number} - the translated months
 * @param hasBullet {boolean} - the translated months
 * @returns {object}
 */ export var getDateAndTime = function(start, stop, months, hasBullet) {
    var timeInMilliseconds = secondsToMilliseconds(start);
    var startDate = new Date(timeInMilliseconds).toLocaleDateString(TIME_FORMAT, {
        day: "2-digit",
        month: "2-digit",
        timeZone: TIMEZONE
    }).replace(/\u200E/g, "");
    var date = startDate.split("/");
    var day = Number.parseInt(date[0]);
    var monthIndex = Number.parseInt(date[1]) - 1;
    var startTime = formatTime(start);
    var endTime = formatTime(stop);
    var separatorContent = hasBullet ? ' <span class="date-time-bullet">&bull;</span>' : ",";
    return {
        __html: "<span>".concat(day, " ").concat(months[monthIndex]).concat(separatorContent, "</span> ").concat(startTime, " - ").concat(endTime)
    };
};
/**
 * Get a formatted string with bullets separator and Live indicator if showLiveLabel is true
 * @param start {number}
 * @param stop {number}
 * @param months {number}
 * @param showLiveLabel {boolean}
 * @returns {object}
 */ export var getDateAndTimeWithLive = function(start, stop, months, showLiveLabel) {
    var initialDateAndTime = getDateAndTime(start, stop, months, true);
    if (showLiveLabel) {
        initialDateAndTime.__html += ' <span class="date-time-bullet">&bull;</span> <span class="date-time-live">Live</span>';
    }
    return initialDateAndTime;
};
/**
 * Get the today's Date.
 * @returns {Date}
 */ export var getToday = function() {
    // Get server timestamp.
    var nowInTimestamp = new Date().getTime() - secondsToMilliseconds(config.timestampOffset);
    // Get the time wherever you are.
    var now = new Date(nowInTimestamp);
    // Get today's date string for a timezone.
    var dateInTimezone = now.toLocaleDateString("en-US", {
        timeZone: TIMEZONE
    });
    return new Date(dateInTimezone);
};
/**
 * Checks if google cast service is available
 * @returns {Boolean}
 */ export var isCastAvailable = function() {
    return !!(cast && cast.framework && cast.framework.CastContext);
};
/**
 * Get string formatted like:
 * 'Saturday, 24th of March ' or 'yesterday, 25th of March'
 * depending on the day.
 * @param date {Date}
 * @param days {Array<string>}
 * @param months {Array<string>}
 * @param otherDays {Object}
 * @returns {string}
 */ export var getDayOfWeekAndDate = function(date, days, months, otherDays) {
    var dayOfWeekIndex = date.getDay();
    var dayOfWeek = days[dayOfWeekIndex];
    var dayInMonth = date.getDate();
    var monthIndex = date.getMonth();
    var todayDate = getToday();
    // 'todayDate' is mutated here!
    var yesterdayDate = new Date(todayDate.setDate(todayDate.getDate() - 1));
    if (date.getDate() === getToday().getDate()) {
        dayOfWeek = otherDays && otherDays.today ? otherDays.today : dayOfWeek;
    } else if (date.getDate() === yesterdayDate.getDate()) {
        dayOfWeek = otherDays && otherDays.yesterday ? otherDays.yesterday : dayOfWeek;
    }
    var month = months[monthIndex];
    return "".concat(dayOfWeek, ", ").concat(dayInMonth, " ").concat(month);
};
/**
 * Get a date object pointing to the beginning of
 * the corresponding day for a timezone.
 * @param timestamp {number}
 * @returns {Date}
 */ export var getStartOfDayDateObject = function(timestamp) {
    var date = new Date(timestamp);
    var localeDate = date.toLocaleDateString("en-US", {
        timeZone: TIMEZONE
    });
    return new Date(localeDate);
};
var MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;
/**
 * Get the difference between two dates in days.
 * @param a {Date}
 * @param b {Date}
 * @returns {number}
 */ export var dateDifferenceInDays = function(a, b) {
    var utcA = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utcB = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utcA - utcB) / MILLISECONDS_IN_A_DAY);
};
/**
 * Transforms a timestamp from seconds to milliseconds.
 * This is used in order to transform the data from
 * the backend API in a more declarative manner and
 * avoid multiplying to 1000 in the code.
 * @param seconds {number}
 * @returns {number}
 */ export var secondsToMilliseconds = function(seconds) {
    return seconds * 1000;
};
/**
 * Sets the object describing the token
 * in the local storage.
 * @param tokenObj {Object}
 */ export var setTokenObject = function(tokenObj) {
    // Without this check the tests throw error.
    if (typeof localStorage === "undefined") {
        return;
    }
    try {
        localStorage.setItem("tokenObject", JSON.stringify(tokenObj));
    } catch (error) {
        console.error("Can't set token object in local storage.", error);
    }
};
/**
 * Retrieves the token object from local storage.
 * @returns {Object || null}
 */ export var getTokenObject = function() {
    // Without this check the tests throw error.
    if (typeof localStorage === "undefined") {
        return;
    }
    var tokenObject = null;
    try {
        var tokenObjectString = localStorage.getItem("tokenObject");
        tokenObject = JSON.parse(tokenObjectString);
    } catch (error) {
        console.error("Error getting token object from local storage.", error);
    }
    return tokenObject;
};
/**
 * Removes the token object from local storage.
 */ export var deleteTokenObject = function() {
    // Without this check the tests throw error.
    if (typeof localStorage === "undefined") {
        return;
    }
    try {
        localStorage.removeItem("tokenObject");
    } catch (error) {
        console.error("Error deleting token object from local storage.", error);
    }
};
/**
 * Removes the order object from session storage.
 */ export var deleteOrderObject = function() {
    // Without this check the tests throw error.
    if (typeof sessionStorage === "undefined") {
        return;
    }
    try {
        sessionStorage.removeItem("orderInfo");
    } catch (error) {
        console.error("Error deleting order object from session storage.", error);
    }
};
export var setSessionLockTime = function() {
    var time = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : Number.POSITIVE_INFINITY, isSessionStorage = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    if (typeof sessionStorage === "undefined" || typeof localStorage === "undefined") {
        return null;
    }
    try {
        (isSessionStorage ? sessionStorage : localStorage).setItem("timeToLockChannels", time === Number.POSITIVE_INFINITY ? Number.POSITIVE_INFINITY : JSON.stringify(time));
    } catch (e) {
        console.error("Error setting session lock time.", e);
        return null;
    }
};
export var getSessionLockTime = function() {
    var isSessionStorage = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    if (typeof sessionStorage === "undefined" || typeof localStorage === "undefined") {
        return null;
    }
    try {
        var value = (isSessionStorage ? sessionStorage : localStorage).getItem("timeToLockChannels");
        return value === Number.POSITIVE_INFINITY ? Number.POSITIVE_INFINITY : JSON.parse(value);
    } catch (e) {
        console.error("Error getting session lock time.", e);
        return null;
    }
};
export var deleteSessionLockTime = function() {
    var isSessionStorage = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    if (typeof sessionStorage === "undefined" || typeof localStorage === "undefined") {
        return null;
    }
    try {
        (isSessionStorage ? sessionStorage : localStorage).removeItem("timeToLockChannels");
    } catch (e) {
        console.error("Error removing session lock time.", e);
        return null;
    }
};
/**
 * Checks if a string is a valid email address.
 * Then executes a callback with the result of the
 * check, provided as an argument.
 * @param {string} email
 * @param {Function} callback
 * @returns {boolean}
 */ export var validateEmail = function(email, callback) {
    var trimmedEmail = email.replace(/\s/g, "");
    var isValid = REGEX_EMAIL.test(trimmedEmail);
    if (typeof callback === "function") {
        callback(isValid);
    }
    return isValid;
};
/**
 * Checks if a string is a valid password.
 * Then executes a callback with the result of the
 * check, provided as an argument.
 * @param {string} password
 * @param {Function} callback
 * @returns {boolean}
 */ export var validatePassword = function(password, callback) {
    var isValid = REGEX_PASSWORD.test(password);
    if (typeof callback === "function") {
        callback(isValid);
    }
    return isValid;
};
/**
 * Checks if the error is form the list of the
 * defined server errors that have a specific subcode.
 * @param error {Object}
 * @returns {boolean}
 */ export var hasSpecificServerError = function(error) {
    return error && error.response && error.response.data && error.response.data.subcode;
};
/**
 * Checks if the response has
 * authorization data.
 * @param response {Object}
 * @returns {boolean}
 */ export var hasTokenObject = function(response) {
    return response && response.data && response.data.data && response.data.data.access_token;
};
/**
 * Creates an object with keys corresponding to
 * the id of major categories that are present
 * in the epgs. Each such key holds object with
 * id, name of the category, epgs array and
 * subcategories object that has the same structure
 * - keys are the ids of the subcategories and
 * each subcategory is an object with id, name
 * and epgs array.
 * @param {Array<Object>} epgs
 * @returns {Object}
 */ export var structureEpgArray = function(epgs) {
    var profileSettings = store.getState().profileSettings;
    // Filter out hidden and locked channels
    var filtered = epgs.filter(function(epg) {
        return checkVisibleStatusForChannel(profileSettings, epg.chanId) && !checkLockStatusForChannel(profileSettings, epg.chanId);
    });
    return filtered.reduce(function(res, current) {
        res[current.majorId] = res[current.majorId] || {};
        res[current.majorId].name = current.major_category || "";
        res[current.majorId].id = current.majorId;
        res[current.majorId].epgs = res[current.majorId].epgs || [];
        res[current.majorId].epgs.push(current);
        res[current.majorId].subcategories = res[current.majorId].subcategories || {};
        current.category_array.forEach(function(cat, index) {
            res[current.majorId].subcategories[cat] = res[current.majorId].subcategories[cat] || {};
            res[current.majorId].subcategories[cat].name = current.categories[index] || "";
            res[current.majorId].subcategories[cat].id = cat;
            res[current.majorId].subcategories[cat].epgs = res[current.majorId].subcategories[cat].epgs || [];
            res[current.majorId].subcategories[cat].epgs.push(current);
        });
        return res;
    }, {});
};
/**
 * Formats the date from milliseconds to dd.mm.yy
 * @param {number} seconds
 */ export var formatDate = function(seconds) {
    var withMonthName = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
    var _store_getState_i18n;
    var months = (_store_getState_i18n = store.getState().i18n) === null || _store_getState_i18n === void 0 ? void 0 : _store_getState_i18n.months;
    if (withMonthName && !isEmpty(months)) {
        return formatDateWithMonthName(seconds, months, true);
    }
    var date = new Date(secondsToMilliseconds(seconds));
    return date.toLocaleDateString(TIME_FORMAT).replace(/\//g, ".");
};
export var formatDateWithMonthName = function(seconds, months) {
    var showYear = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    var timeInMilliseconds = secondsToMilliseconds(seconds);
    var startDate = new Date(timeInMilliseconds).toLocaleDateString(TIME_FORMAT, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: TIMEZONE
    }).replace(/\u200E/g, "");
    var date = startDate.split("/");
    var day = Number.parseInt(date[0]);
    var year = Number.parseInt(date[2]);
    var monthIndex = Number.parseInt(date[1]) - 1;
    return "".concat(day, " ").concat(months[monthIndex].slice(0, 3)).concat(showYear ? " ".concat(year) : "");
};
export var formatDateSubscriptionToday = function() {
    var date = new Date();
    return date.toLocaleDateString(TIME_FORMAT).replace(/\//g, ".");
};
export var formatDateSubscriptionAfterAMonth = function(seconds) {
    var today = new Date();
    var dayAfterOneMonth = new Date();
    dayAfterOneMonth = new Date(dayAfterOneMonth.setDate(today.getDate() + 30));
    return dayAfterOneMonth.toLocaleDateString(TIME_FORMAT).replace(/\//g, ".");
};
/**
 * Formats the date to dd.mm.yy
 * @param date
 * @returns {string}
 */ export var formatDateFromString = function(date) {
    return date.toLocaleDateString(TIME_FORMAT).replace(/\//g, ".");
};
export var getTimeFromTimeStamp = function(ts) {
    var date = new Date(ts);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    return "".concat(pad(hours), ":").concat(pad(minutes));
};
export var getTimeWithHoursFromTimeStamp = function(ts) {
    var date = new Date(ts);
    var day = date.getDate();
    if (day < 10) {
        day = "0".concat(day);
    }
    var month = date.getMonth() + 1;
    if (month < 10) {
        month = "0".concat(month);
    }
    var hours = date.getHours();
    var minutes = date.getMinutes();
    return "<strong>".concat(day, ".").concat(month, "</strong> в <strong>").concat(pad(hours), ":").concat(pad(minutes), "</strong>");
};
/**
 * Returns number of day in specific month
 * @param month
 * @param year
 * @returns {number}
 */ export var getDaysInMonth = function(month, year) {
    return new Date(year, month, 0).getDate();
};
/**
 * Formats the currency value from thousands (cents) to whole unit with 2 signs after decimal point
 * @param value {number} (cents)
 * @param currency {string}
 * @param currencyConversion {{ currency: string, conversion_rate: number }}
 */ export var formatPrice = function(value, currency) {
    var currencyConversion = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : null, ignoreConversionRate = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
    if (typeof value !== "number") {
        return "";
    }
    if (!currency) {
        return (value / 100).toFixed(2).replace("-", "- ");
    }
    var convertedCurrency = currency;
    var convertedValue = value;
    if ((currencyConversion === null || currencyConversion === void 0 ? void 0 : currencyConversion.conversion_rate) && currencyConversion.currency !== currency) {
        convertedCurrency = currencyConversion.currency;
        if (!ignoreConversionRate) {
            convertedValue = value * currencyConversion.conversion_rate;
        }
    }
    var currencyFormat = Intl.NumberFormat(convertedCurrency === "BGN" ? "bg-BG" : "en-GB", {
        style: "currency",
        currency: convertedCurrency
    });
    // If the value is negative, add a space after the minus sign
    return currencyFormat.format(convertedValue / 100).replace("-", "- ");
};
/**
 * Transforms card number from BE in format ************1111
 * to format •••• •••• •••• 1111
 * @param cardNumber {string}
 * @returns {string}
 */ export var formatCardNumber = function(cardNumber) {
    return cardNumber.replace(/(\*{4})/g, "$1 ").split("*").join("•");
};
/**
 * Calculates menu width based on window innerWidth
 * @returns {string}
 */ export var calculateMenuWidth = function() {
    var menuWidth = "200px";
    var windowWidth = window.innerWidth;
    if (windowWidth <= SMALL_MOBILE_TYPE_PX) {
        menuWidth = "280px";
    } else if (windowWidth <= MOBILE_TYPE_PX) {
        menuWidth = "260px";
    } else if (windowWidth <= TABLET_TYPE_PX) {
        menuWidth = "250px";
    }
    return menuWidth;
};
/**
 * constant returns array of packages which users can watch them in android devices
 * @returns {Array}
 */ export var ONLY_ANDROID_DEVICES = [
    "EROS PACK"
];
/**
 * Helper function for figuring out if you should reset the lock status
 * of the channels.
 * This functions uses the Redux store for accesing the current props.
 * For proper use of this functionality the component that you use this helper
 * should have mounted these specific props from the redux store:
 *
 * 'videoPlayer',
  'liveChannels',
  'filteredLiveChannels',
  'profileSettings.web_settings.timeToLockChannels',
  'profileSettings.web_settings.lockPeriod',
 *
 * @param {Object} prevProps
 * @param {Object} currentProps
 * @returns {Boolean} - if it should reset the local lock status of the channels
 */ export var shouldResetLockStatus = function(prevProps, currentProps) {
    var keys = [
        "videoPlayer",
        "liveChannels",
        "filteredLiveChannels",
        "profileSettings.web_settings.timeToLockChannels",
        "profileSettings.web_settings.lockPeriod"
    ];
    if (diffObjects(prevProps, currentProps, keys)) {
        // Reset lock status to unknown
        return true;
    }
    return false;
};
export var initializeChagebee = function() {
    if (isEmpty(HTML_TEMPLATE_CHARGEBEE_SITE) || isNil(Chargebee)) {
        console.warn("Chargebee is not initialized. Please provide the site and initialize the Chargebee library.");
        return;
    }
    try {
        Chargebee.init({
            site: HTML_TEMPLATE_CHARGEBEE_SITE,
            enableGATracking: true,
            iframeOnly: true
        });
    } catch (error) {
        console.error("Error initializing Chargebee.", error);
    }
};
export function durationToLocaleString(duration, i18n) {
    if (!duration) return "";
    if (duration.period === 1) {
        return "".concat(duration.period, " ").concat(i18n.account[duration.periodUnit]);
    }
    return "".concat(duration.period, " ").concat(i18n.account["".concat(duration.periodUnit, "s")]);
}
/**
 * @param {{ conversion_rate: number, currency: string }} subscriptionsPrismic
 */ export function stripCurrencyConversion(param) {
    var conversion_rate = param.conversion_rate, currency = param.currency;
    if (!conversion_rate) return null;
    return {
        conversion_rate: conversion_rate,
        currency: currency
    };
}
